<template>
  <div class="footer-warp">
    <div class="footer">
      <!-- <div class="footer_one" @click="jumpHCX">
        <div class="footer_one_top">
          <img :src="hcxChecked" alt="" v-if="icon == 0" />
          <img :src="hcxIcon" alt="" vv-else />
        </div>
        <div class="footer_one_bottom" :class="[icon == 0 ? 'active' : 'ordinary']">惠出行</div>
      </div> -->
      <div class="footer_two" @click="jumpCode">
        <div class="footer_two_top">
          <img :src="RideIcon1" v-if="icon == 1" alt="" />
          <img :src="RideIcon" v-else alt="" />
        </div>
        <div class="footer_two_bottom" :class="[icon == 1 ? 'active' : 'ordinary']">乘车</div>
      </div>
      <div class="footer_three" @click="jumpMy">
        <div class="footer_three_top">
          <img :src="myChecked" alt="" v-if="icon == 2" />
          <img :src="my" alt="" v-else />
        </div>
        <div class="footer_three_bottom" :class="[icon == 2 ? 'active' : 'ordinary']">我的</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      RideIcon: require('@/assets/image/bayBus@2x.png'),
      RideIcon1: require('@/assets/image/bayBusSelect@2x.png'),
      hcxIcon: require('@/assets/image/hcxIcon@2x.png'),
      hcxChecked: require('@/assets/image/hcx-checked@2x.png'),
      my: require('@/assets/image/my@2x.png'),
      myChecked: require('@/assets/image/my-checked@2x.png'),
    };
  },

  props: {
    icon: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.log(this.icon);
  },
  methods: {
    jumpCode() {
      this.$emit('jumpCode', true);
    },
    // jumpHCX() {
    //   upsdk.openApplet({
    //     appId: 'a0aa07052eb5cc70',
    //     param: 'key1=1&key2=2', //可选，拼在小程序首页url后的查询参数 isFinish: '1', // 可选, '1'表示关闭当前页面 非'1'不做处理, 默认为0 toLink:'url', //可选，打开小程序时打开指定页，url不做编码，url要求域名在小程序安全域名范围内，仅支持云闪付9.0以上版本 fail:function(code){ //code=101表示appId为空,code=102表示找不到该应用 }
    //   });
    // },
    jumpMy() {
      this.$emit('jumpMy', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-warp {
  width: 100%;
  .footer {
    height: 0.6rem;
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.55rem 0 0.55rem;
    box-sizing: border-box;
    .footer_one {
      display: flex;
      align-items: center;
      flex-direction: column;
      .footer_one_top {
        width: 0.2rem;
        height: 0.22rem;
        margin-bottom: 0.04rem;

        img {
          width: 0.2rem;
          height: 0.2rem;
        }
      }
      .footer_one_bottom {
        font-size: 0.1rem;
        color: #7f7f80;
      }
    }
    .footer_two {
      display: flex;
      align-items: center;
      flex-direction: column;

      .footer_two_top {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 0.22rem;
          width: 0.22rem;
        }
      }
      .footer_two_box {
        width: 0.2rem;
        height: 0.22rem;
        margin-bottom: 0.04rem;
      }
      .footer_two_bottom {
        font-size: 0.1rem;
      }
    }
    .footer_three {
      display: flex;
      align-items: center;
      flex-direction: column;

      .footer_three_top {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 0.2rem;
          height: 0.22rem;
        }
      }
      .footer_two_box {
        width: 0.2rem;
        height: 0.22rem;
        margin-bottom: 0.04rem;
      }
      .footer_three_bottom {
        font-size: 0.1rem;
      }
    }
  }
  .active {
    color: #ed171f;
  }
  .ordinary {
    color: #7f7f80;
  }
}
</style>

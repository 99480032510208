// var CryptoJS = require('./tripledes')
const CryptoJS = require('../crypto/tripledes');
export function tripleDesEncrypted(src, key) {
  console.log(' test ');

  try {
    var keyHex = CryptoJS.enc.Utf8.parse('D7DF888F50A363F2');
    var keyHex = CryptoJS.enc.Hex.parse('D7DF888F50A363F2');

    var src = CryptoJS.enc.Hex.parse('01136DCCA7620060');

    console.log('1111111');
    // console.log(CryptoJS);
    const encrypted = CryptoJS.DES.encrypt(src, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.NoPadding,
    });
    console.log(encrypted.toString());
    console.log(encrypted.ciphertext.toString());
    return encrypted.ciphertext.toString();
  } catch (err) {
    console.log(`error = ${err}`);
  }
}

export function getKey() {}

// hex 转string
function myHextoString(hex) {
  let out = '';
  for (let i = 0; i < hex.length; i++) {
    let h = hex[i] / 16;
    if (h >= 10) {
      h = h - 10 + 97;
    } else {
      h += 48;
    }
    h = String.fromCharCode(h);
    out += h;

    let l = hex[i] % 16;
    if (l >= 10) {
      l = l - 10 + 97;
    } else {
      l += 48;
    }
    l = String.fromCharCode(l);
    out += l;
  }
  return out;
}

// string 转hex
export function myStringtoHex(str) {
  // str = str.toLowerCase();
  const newBuffer = new ArrayBuffer(str.length / 2);
  const hexBuffer = new Uint8Array(newBuffer, 0);
  let h = 0,
    l = 0;
  for (let i = 0; i < str.length / 2; i++) {
    h = str.charCodeAt(2 * i);

    l = str.charCodeAt(2 * i + 1);
    if (h >= 48 && h <= 57) {
      h -= 48;
    } else {
      h = h - 97 + 10;
    }
    if (l >= 48 && l <= 57) {
      l -= 48;
    } else {
      l = l - 97 + 10;
    }
    hexBuffer[i] = h * 16 + l;
  }
  return hexBuffer;
}

/**
 *  手机号11位转16进制-长度12位, 最后一位随机
 */
export const strToHexCharCode = str => {
  if (str === '') return '';
  return (
    [...str]
      .map(el => {
        return +el + 6 >= 10 ? (+el + 6).toString(16) : +el + 6;
      })
      .join('')
      .toUpperCase() + parseInt((""+(Math.random()*1000000)%16)).toString(16).toUpperCase()
  );
};

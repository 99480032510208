<template>
  <div class="swiper" v-if="adUrls.length > 0">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        class="swipe_item"
        @click="jumpIconUrl(item)"
        v-for="(item, index) in adUrls"
        :key="item.id"
      >
        <img class="swipeImg" :src="item.image" alt />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { newsUrl, matomoOpen } from '@/unified';
import { EventBus } from '@/common/event-bus.js';
import { jumpPage } from '@/utils';

export default {
  props: {
    channelId: {
      type: String,
      default: '',
    },
    refresh: {
      type: String,
      default: '',
    },
    disableInitLoad: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      enableHCXAd: false,
      adUrls: [],
    };
  },
  beforeDestroy() {
    EventBus.$off('PageShow');
  },
  mounted() {
    EventBus.$on('PageShow', msg => {
      console.log('广告页面' + msg);
      this.enableHCXAd = '1' == localStorage.getItem('enableHCXAd');
      if (this.enableHCXAd) {
        this.getNewsList();
      }
    });
    if (this.disableInitLoad == '0') {
      this.enableHCXAd = '1' == localStorage.getItem('enableHCXAd');
      if (this.enableHCXAd) {
        this.getNewsList();
      }
    }
  },
  methods: {
    // 获取广告
    getNewsList() {
      var time = new Date().getTime();
      var newDate = dayjs(time).format('YYYYMMDDHH');
      this.$axios({
        method: 'get',
        url: newsUrl + '/api/news/list?channelId=' + this.channelId + '&_=' + newDate,
      })
        .then(response => {
          //这里使用了ES6的语法
          this.adUrls = response.data.data;
          console.log('广告图片', this.adUrls);
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
        });
    },
    refreshAd() {
      console.log(
        'refreshAd, local: ' +
          localStorage.getItem('enableHCXAd') +
          ', this.enableHCXAd: ' +
          this.enableHCXAd,
      );
      if (localStorage.getItem('enableHCXAd') == this.enableHCXAd) {
        return;
      }

      this.enableHCXAd = '1' == localStorage.getItem('enableHCXAd');
      if (this.enableHCXAd) {
        this.getNewsList();
      } else {
        this.adUrls = [];
      }
    },
    openWithLocation(item) {
      if (this.refresh) {
        console.log('是乘车页面');
        this.$emit('bleStop');
      }
      window.location.href = item.href;
    },
    jumpIconUrl(item) {
      console.log(item);

      if (matomoOpen && this.$matomo) {
        this.$matomo.trackEvent('adClick', item.id);
      }

      if (item.href) {
        var url = new URL(item.href);
        console.log('打开广告页面: ' + item.href);
        if (url.protocol != 'https:' && url.protocol != 'http:') {
          jumpPage(item, this.$router);
        } else {
          if (upsdk.createWebView && !item.innerLink) {
            try {
              upsdk.createWebView({
                url: item.href,
                isFinish: 0,
              });
            } catch (e) {
              this.openWithLocation(item);
            }
          } else {
            this.openWithLocation(item);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  height: 1rem;
  width: 100%;
  padding: 0 0.16rem;
  box-sizing: border-box;
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .my-swipe {
    width: 3.44rem;
    height: 1rem;
    border-radius: 0.1rem;
    .van-swipe-item {
      width: 3.44rem;
      height: 1rem;
      border-radius: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .swipeImg {
        width: 3.44rem;
        height: 1rem;
        border-radius: 0.1rem;
      }
    }
  }
}
</style>

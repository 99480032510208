/**
 * getters
 */

import {
  state
} from './state';

export const getters = {
  auth: state => state.auth,
  CodeInfo: state => state.CodeInfo,
  opebId: state => state.opebId,
  isAbnormal: state => state.isAbnormal,
  sameStationInOutShow: state => state.sameStationInOutShow,
  prohibit: state => state.prohibit,
  status: state => state.status,
  msg: state => state.msg,
  list: state => state.list,
  ticket: state => state.ticket,
  queryInfo: state => state.queryInfo,
  logAdd: state => state.logAdd,
  // 日票
  dayQrcodeInfo: state => state.dayQrcodeInfo,

  dayOrAsingle: state => state.dayOrAsingle,
};

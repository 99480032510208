var appId = 'da45073bb3b2420bbe641af72473fdba';
var redirectUri =
  'http://testmetroticket.tongyindigitalqpay.com:8200/dtb/unionPayMiniApp/contractApply'; // 签约重定向的
var responseType = 'code';
var scope = 'upapi_contract';
var planId = 'eadeaf6f546f425298e0a300dfdb77aa';
var repayment = 'http://testmetroticket.tongyindigitalqpay.com:8100/?'; // 次票小程序项目地址
var PwdHtml = 'https://open.95516.com/s/open/noPwd/html/open.html?'; //无感支付签约地址

var baseUrl = 'https://testmetroticket.tongyindigitalqpay.com'; // 测试地址
var financeBaseUrl='https://testmetroticket.tongyindigitalqpay.com'
var newsUrl = 'https://testmetroticket.tongyindigitalqpay.com';
// 模拟闸机开关，打开后不校验31报文mac
var mockZhaji = false;
var locationShow = true;
var version = 470;
var appletId = '8daae5f227395104';
var host = 'http://101.52.125.248:8080/track/';
var trackerScriptUrl = 'http://101.52.125.248:9090/matomo.js';
var trackerFileName = 'matomo';
var matomoOpen = true;
var ysfMarketUrlPattern = 'http://101.231.114.212:10002/traffic-marketing/html/merchantList.html?appId=[appId]&openId=[openId]&opId=C310020180200001&serviceId=01&stationNo=[stationNo]&stationNm=[stationNm]&lineNo=[lineNo]&lineNm=[lineNm]&direction=01&cityId=3100';
var NODE_ENV='test'
var CHANNEL='YUNSHANFU';
export {
  appId,
  redirectUri,
  responseType,
  scope,
  planId,
  repayment,
  PwdHtml,
  baseUrl,
  financeBaseUrl,
  newsUrl,
  mockZhaji,
  version,
  appletId,
  host,
  trackerScriptUrl,
  trackerFileName,
  matomoOpen,
  ysfMarketUrlPattern,
  locationShow,
  NODE_ENV,
  CHANNEL,
};

import { EventBus } from '../event-bus.js';
import dayjs from 'dayjs';
import { hex2base64, base64ToHex } from '../utils';
import { isOpenBlueTooth, getSystemInfo, checkBlutoothStatus } from '../jssdk';
import { Dialog, Toast } from 'vant';
const QR = require('./SHMQRCode');
import { setLocalData, getLocalData, delLocalData } from '../jssdk';
import {
  state
} from '@/store/state';
// 物理bom连接
let isBomConnect = false;

// 订阅
let isSubscribe = false;

// 是否31更新
let isupDate = false;
// 32数据
let updateData = '';

let isAddEvent = false;

let operatorDelegate;

// 从模式开启后返回的ServerId
let BLEServerId;
// 特征值
let broadcast;
// 与设备是否已连上
export var isConnectedDevice = false;

// 是否正在广播
let serverBroadcasting = false;

// 是否已调用过configApplePay
let configApplePayCalled = false;

let UUID_SERVICE; // 服务UUID
let UUID_READ; // 上行传输通道UUID
let UUID_WRITE; // 下行传输通道UUID

let cbId;

export function initUUID(uuid_service, uuid_read, uuid_write) {
  UUID_SERVICE = uuid_service;
  UUID_READ = uuid_read;
  UUID_WRITE = uuid_write;
}

let isIOS = true;
let refreshTime = '';

// 代理方法
export function delegate(delegate) {
  operatorDelegate = delegate;
}

export function initOSType(_os) {
  isIOS = _os;
}

export async function start(flag) {
  console.log('BLE  start');
  const shmqr = await QR.getQRCode(flag);
  console.log('start shmqr: ', shmqr);
  if (!shmqr || shmqr.length != 256) {
    console.log('二维码不合法，直接返回' + shmqr);
    return false;
  }
  if (state.status != 9999) {
    console.log('生码异常需要禁码')
    return false;
  }
  // 获取广播
  broadcast = QR.getBroadCast();
  // operatorDelegate.refreshQRCode(shmqr, false);

  // startPeripheral()
  // TODO:
  const blu = await startPeripheral();
  if (blu == 'blestarted') {
    console.log('蓝牙已启动，不再重复启动');
  } else if (!blu) {
    return false;
  }
  // 回调二维码数据
  console.log('BLE  start - 完成start调用');
  //operatorDelegate.refreshQRCode(shmqr, false);
  return shmqr;
}

//  开启从模式蓝牙 并初始化
export async function startPeripheral() {
  const [res] = await canIUseBLEService();
  if (!res) {
    console.log('判断安卓NFC 以及 ios applePay 的开启状态  不允许使用蓝牙');
    return false;
  }
  if (isAddEvent) {
    console.log('已经开了一次广播了');
    return 'blestarted';
  }

  console.log('蓝牙相关-------');
  upsdk.createBLEPeripheralServer({
    async success(data) {
      BLEServerId = data.serverId;
      // await setLocalData({
      //   key: 'newServerId',
      //   value: {
      //     serverId: data.serverId,
      //   }
      // })
      localStorage.setItem('newServerId', data.serverId);
      await addServerId2Local(BLEServerId);
      // serverId, 接口调用成功后生成的serverId
      // await startBroadCast();
      await startBlueToothService();
    },
    fail({ code }) {
      console.log('建立本地作为外围设备的服务端，可创建多个-fail', code);
      if (+code === 1001) {
        removeAllOldPeripheralAndStart();
      }
    },
  });
  return true;
}

export async function canIUseBLEService() {
  return new Promise(async resolve => {
    if (!isIOS) {
      console.log('---------安卓系统打印----------');

      var sys = await getSystemInfo();
      if (sys.platform == 'Android') {
        var passed = parseInt(sys.version) > 282;

        if (!passed && parseInt(sys.version) == 282) {
          var ua = navigator.userAgent;

          var match = ua.match(/\(clientVersion (.*?)\) \(version (.*?)\)/i);
          console.log('版本检查，ua: ' + ua, match);

          passed = match && match[2] && parseInt(match[2]) >= 927;
        }

        if (!passed) {
          console.error('用户安卓手机版本小于等于282(9.2.6),需升级');
          Toast.clear();
          Dialog.confirm({
            title: '提示',
            message:
              '<div>需要升级云闪付最新版本使用乘车码小程序</div><div style="font - weight: 600;margin: 0.1 rem 0;">是否立即升级?</div><div style="font - size: 0.12 rem;text - align: left;">(注意:点击“升级版本”如无法跳转到升级页面，请至手机应用市场升级云闪付)</div>',
            showCancelButton: false,
            confirmButtonText: '升级版本',
          }).then(() => {
            console.log('下载最新升级包');
            window.location.href = 'https://youhui.95516.com/hybrid_v4/html/help/download.html';
            resolve([false]);
          });

          return false;
        }
      }
    } else {
      console.log('---------ios系统打印----------');
    }
    // 判断蓝牙状态
    const [res] = await isOpenBlueTooth();
    if (!res) {
      console.log('蓝牙未开启' + new Date().toLocaleTimeString());
      Toast.clear();
      resolve([false]); // 返回false 关闭蓝牙重新进入后隐藏二维码页面显示弹窗

      console.log('显示 小程序想要开启蓝牙');

      Dialog.confirm({
        title: '提示',
        message: '小程序想要开启蓝牙',
        showCancelButton: true,
        cancelButtonText: '拒绝',
        confirmButtonText: '允许',
      })
        .then(() => {
          console.log('允许开启蓝牙' + new Date().toLocaleTimeString());
          if (!isIOS) {
            // 安卓
            console.log('安卓系统打开请求打开蓝牙，并监听蓝牙开启状态');
            upsdk.openBluetooth({
              success: function(code) {
                console.log(`安卓系统蓝牙打开成功 ${new Date().toLocaleTimeString()}  ${code}`);

                resolve([checkBlutoothStatus()]);
              },
              fail: function(code) {
                console.log('安卓系统打开蓝牙失败', code);
                Dialog.confirm({
                  title: '提示',
                  message: '自动开启蓝牙失败，请手动开启',
                  showCancelButton: false,
                  confirmButtonText: '确认',
                }).then(() => {
                  console.log('确认');
                  resolve([false]);
                });
              },
            });
          } else {
            // ios
            console.log('ios系统-去打开蓝牙设置');
            upsdk.openBluetoothSetting({
              success: function() {
                console.log('打开蓝牙设置-success');
              },
              fail: function() {
                console.log('打开蓝牙设置-error');
              },
            });
          }
        })
        .catch(() => {
          Dialog.confirm({
            title: '提示',
            message: '必须开启蓝牙才能使用乘车功能',
            showCancelButton: false,
            confirmButtonText: '确认',
          }).then(() => {
            console.log('确认');
            resolve([false]);
          });
          console.log('拒绝');

          // resolve['拒绝蓝牙开启']
        });
    } else {
      // 蓝牙开启状态里面
      // 判断安卓NFC 以及 ios applePay 的开启状态
      if (!isIOS) {
        resolve([true]);
      } else {
        // 关闭Apple Pay
        if (configApplePayCalled) {
          console.log('已调用iOS关闭Apple Pay，不再重复调用');
        } else {
          configApplePayCalled = true;
          console.log('iOS关闭Apple Pay');
          upsdk.configApplePay({
            enable: '0', // enable:0（关闭）;enable:1（打开）
            success: function(data) {
              // 插件执行成功
              console.log('iOS关闭Apple Pay-success', data);
            },
            fail: function(res) {
              console.log('iOS关闭Apple Pay-fail', res);
            },
          });
        }

        resolve([true]);
      }
    }
    // resolve([true]);
  });
}

function startBroadCast() {
  console.log('startBroadCast: ' + BLEServerId);
  if (serverBroadcasting) {
    console.log('BLEServerId已广播，不再重复广播: ' + BLEServerId);
    return false;
  }

  serverBroadcasting = true;

  broadcast = QR.getBroadCast();
  console.log('广播: ' + broadcast);

  const broadCastParams = {
    serverId: BLEServerId, // createBLEPeripheralServer 返回的serverId
    powerLevel: 'high',
    advertiseRequest: {
      connectable: '1',
      deviceNameNeed: '0', //10月11号加的
      powerLevelNeed: '0', // 10月11号加的
      timeOut: '0', // 10月11号加的
      advertiseModel: '2', // 10月11号加的
      ...(isIOS
        ? {
            deviceName: broadcast, // 7EC8D69E98D0
            serviceUuids: [UUID_SERVICE],
          }
        : {
            deviceName: broadcast,
            manufactuerData: [
              {
                manufacturerId: 'TAA=', // btoa('0xFF')  // 改过的地方
                manufacturerSpecificData: hex2base64(`${broadcast}`), // 蓝牙特征值  === fsjWnpjQ
              },
            ],
          }),
    },
  };

  console.log('broadCastParams参数', broadCastParams);

  upsdk.startAdvertising({
    ...broadCastParams,
    success: function(data) {
      console.log('开始广播本地创建的外围设备 成功', new Date().getTime());
      //console.log('开始广播本地创建的外围设备 成功', data);
      // startBlueToothService();
      addEvent();

      if (isIOS) {
        operatorDelegate.refreshQRCode(QR.getFastQR(), false);
      } else {
        // 安卓延时500毫秒展码
        setTimeout(function() {
          operatorDelegate.refreshQRCode(QR.getFastQR(), false);
        }, 700);
      }
    },
    fail: function({ code }) {
      serverBroadcasting = false;
      console.log('开始广播本地创建的外围设备-fail', code);
      if (+code === 1017) {
        removeAllOldPeripheralAndStart();
      }
      operatorDelegate.refreshQRCode(QR.getFastQR(), false); //11/23后加的
      Toast.clear();
      Dialog.confirm({
        title: '提示',
        message: '蓝牙广播失败，点击刷新重启蓝牙',
        showCancelButton: false,
        confirmButtonText: '刷新',
      }).then(() => {
        console.log('点击刷新了');
        operatorDelegate.refreshCode();
      });
    },
  });
}

function startBlueToothService() {
  const addServiceParams = {
    serverId: BLEServerId,
    service: {
      uuid: UUID_SERVICE, // 000018F0-0000-1000-8000-00805F9B34FB---安卓
      characteristics: [
        {
          uuid: UUID_READ, // 00002AF0-0000-1000-8000-00805F9B34FB---安卓
          value: '', // 新增的参数值为空
          properties: {
            read: '1',
            notify: '1',
            write_no_response: '0', //后加的
            write: '0', //后加的
            indicate: '0', //后加的
            //extendedProps: '1', // 10月11号加的
          },
          permission: {
            readable: '1', // 去掉了writeable
            writeable: '0', //后加的
            readEncryptionRequired: '0', //后加的
            writeEncryptionRequired: '0', //后加的
          },
        },
        {
          uuid: UUID_WRITE, // 00002AF1-0000-1000-8000-00805F9B34FB---安卓
          value: '', // 新增的参数
          properties: {
            write_no_response: '1',
            write: '0', //后加的
            indicate: '0', //后加的
            read: '0', //后加的
            notify: '0', //后加的
            extendedProps: '1', // 10月11号加的
          },
          permission: {
            writeable: '1',
            readable: '0', //后加的
            readEncryptionRequired: '0', //后加的
            writeEncryptionRequired: '0', //后加的
          },
        },
      ],
    },
  };
  upsdk.addService({
    ...addServiceParams,
    success: function(res) {
      console.log('蓝牙创建服务成功success---此处可以截图留念', res);
      // addEvent();
      startBroadCast();
    },
    fail: function(err) {
      console.log('蓝牙创建服务失败', err);
      removeAllOldPeripheralAndStart();
    },
  });
}

function addEvent() {
  if (isAddEvent) {
    console.log('已添加过监听,不再添加');
    return;
  }
  // 监听已连接设备请求写当前外围设备特征值的事件
  // 收到该消息后， 需要立刻调用writeCharacteristicValue写回数据， 否则主机不会收到响应。

  console.log('【【闸机已经可以连接从设备蓝牙了】】');

  // init 34 data
  const data34 = QR.get34data();
  console.log('registerCharacteristicWriteNotification前获取: ' + data34);

  let is33valid = false;

  upsdk.registerCharacteristicWriteNotification({
    serverId: BLEServerId, // createBLEPeripheralServer接口创建的server的serverId
    callback: function(data) {
      console.log(
        `========监听主设备蓝牙写事件的回调成功=======: ` +
          data +
          ', 时间: ' +
          new Date().getTime(),
      );
      // console.log('========监听到了主设备向手机写数据的回调，此处可以截图留念=======');

      const {
        serverId = '',
        serviceId = '',
        characteristicId = '',
        callbackId = '',
        value = '',
      } = data;
      console.log('BLEServerId', BLEServerId);
      console.log('serverId', serverId);
      console.log('value', value);
      if (serverId !== BLEServerId) {
        console.warn(
          '监听主设备蓝牙写事件的回调（iOS）sdk返回serverId与当前serverId(BLEServerId)不同',
        );
      }

      /*
      console.log('is33valid: '+is33valid)
      if(is33valid == '31end'){
        console.log('31已处理完，忽略后续报文： '+base64ToHex(value + ''))
        return;
      }
      */

      cbId = callbackId;
      //console.log(`callbackId: ${callbackId}`);
      is33valid = processData(base64ToHex(value + ''), data34, is33valid, serverId);
    },
    fail: function(err) {
      console.log('【registerCharacteristicWriteNotification】方法调用失败', err);
    },
  });

  //  订阅监听
  if (isIOS) {
    console.log('开始添加订阅监听 registerCharacteristicSubscribedNotification，ios专用');
    upsdk.registerCharacteristicSubscribedNotification({
      serverId: BLEServerId,
      callback: function(data) {
        console.log('success:::[订阅监听]已连接设备请求写当前外围设备特征值的事件', data);
        const { serverId = '', serviceId = '', characteristicId = '' } = data;
        isSubscribe = true;
        updateData = '';
        isupDate = false;
        isBomConnect = false;
        delayAction();
      },
      fail: function(err) {
        console.log('fail:::[订阅监听]已连接设备请求写当前外围设备特征值的事件', err);
      },
    });
  }

  // 安卓特殊处理，现更改为iOS和安卓都调用
  // 需要监听并写一次数据之后才能 监听到闸机的写数据的callback
  // console.log('现更改为iOS和安卓都调用，接下来执行【registerCharacteristicReadNotification】方法')
  // if (!isIOS) {
  // console.log('只有安卓才会有的log，接下来执行【registerCharacteristicReadNotification】方法')
  // upsdk.registerCharacteristicReadNotification({
  //   serverId: BLEServerId, // createBLEPeripheralServer接口创建的server的serverId
  //   callback: function (data) {
  //     console.log('安卓机器执行【registerCharacteristicReadNotification】成功，回调内容为:>> ', JSON.stringify(data));
  //     const {
  //       serverId = '', serviceId = '', characteristicId = '', callbackId = ''
  //     } = data;
  //     // 写一次数据
  //     upsdk.writeCharacteristicValue({
  //       serverId: BLEServerId, // createBLEPeripheralServer接口创建的server的serverId
  //       serviceId, // service的uuid
  //       characteristicId, // characteristic对应的uuid
  //       value: hex2base64('0000'), // 特征值对应的base64编码字符串
  //       needNotify: '1', // 是否需要通知主机value已更新，0:不通知；1:通知；
  //       callbackId, // 处理回包时使用
  //       success: function (res) {
  //         console.log('write success', res);
  //       },
  //       fail: function (err) {
  //         console.log('write fail', err);
  //       },
  //     });
  //   },
  //   fail: function (err) {
  //     console.log('registerCharacteristicReadNotification err data:>> ', err);
  //   },
  // });
  if (!isIOS) {
    console.log('开始监听设备连接状态', new Date().getTime());
    console.log('执行registerBLEPeripheralConnectionStateChangedNotification，安卓专用');
    upsdk.registerBLEPeripheralConnectionStateChangedNotification({
      callback: function(data) {
        const { deviceId = '', serverId = '', connected = '' } = data;

        console.log(
          '安卓连接状态变更, connected: ' +
            connected +
            ', serverId: ' +
            serverId +
            ', BLEServerId: ' +
            BLEServerId,
        );

        if (serverId !== BLEServerId) {
          console.error(
            '监听设备连接状态的sdk回调  sdk返回serverId与当前serverId(BLEServerId)不同,监听失败',
          );
        }

        if (connected === '0') {
          // 断开
          // console.log('监听设备连接状态的sdk回调-- 监听成功,当前状态为断开', data);
          console.log(
            '监听设备连接状态的sdk回调-- 监听成功,当前状态为断开时间',
            new Date().getTime(),
          );
          clearTimeout(refreshTime);
          isBomConnect = false;
          isSubscribe = false;
          isupDate = false;

          disSubscribe();
        } else {
          // 连接
          // console.log('监听设备连接状态的sdk回调 监听成功,当前状态为连接', data);
          console.log(
            '监听设备连接状态的sdk回调 监听成功,当前状态为连接时间',
            new Date().getTime(),
          );

          isSubscribe = true;
          updateData = '';
          isupDate = false;
          isBomConnect = false;

          const timestamp = dayjs().format('h:m:s:SSS');
          console.log('订阅 timestamp = ', timestamp);
          // refreshQRCode();
          delayAction();
        }
      },
      success: function(data) {
        //console.log('监听设备连接sdk监听成功', data);
        console.log('监听设备连接sdk监听成功', new Date().getTime());
      },
      fail: function(err) {
        console.log('监听设备连接sdk监听失败', err);
      },
    });
  }

  // }
  isAddEvent = true;
}

/**
 *
 * @param {*} data
 * @param {*} data34
 * @param {*} is33valid
 * @returns
 */
function processData(data, data34, is33valid, serverId) {
  //console.log('processData data = ' + data + ' @ ' + new Date().getTime());
  if (!checkData(data)) {
    console.log('蓝牙数据 数据校验不合法，直接返回');
    return;
  }

  if (isupDate && data.length == 40) {
    // console.log('蓝牙数据 不是第一次分包数据，对数据进行拼装 data = ' + data);
    updateData = updateData + data;
    // console.log('拼装后得数据' + updateData);
    // console.log('拼装后得数据' + updateData.length);
    rewriteAppCode(updateData, serverId);

    return '31end';
  } else {
    const typeString = data.substr(4, 2);

    // todo 收到33 数据 ，需要记录 时间
    // 33-》34 -》31 -》 32
    // 33 34 握手验签 31 32 数据更新
    if (typeString == '33') {
      // const timestamp = new Date().valueOf();
      // const timestamp = dayjs().format('h:m:s:SSS');
      // const log = {
      //   type: '33',
      //   time: timestamp,
      //   data,
      // };
      // setLog(log);
      if (!processTTData(data, data34, serverId)) {
        return '33invalid';
      }
      return '33valid';
    }

    if (typeString == '31' && is33valid !== '33invalid') {
      console.log('蓝牙数据 分包接受 = ', data);
      console.log('蓝牙数据 第一次接受分包 = ', data);
      const timestamp = dayjs().format('h:m:s:SSS');
      const log = {
        type: '31',
        time: timestamp,
        data,
      };
      setLog(log);
      if (!isupDate) {
        isupDate = true;
        updateData = data;
      } else {
        // 防止31分包数据中包含'31'
        updateData = updateData + data;
        rewriteAppCode(updateData, serverId);
      }
    }
  }
}

/**
 *
 * @param {*} data
 * @param {*} data34 34
 */
async function processTTData(data, data34, serverId) {
  //console.log('闸机返回数据', data)
  data34 = QR.get34data();
  //console.log('重新获取: ' + data34)

  // 收到33后隐藏二维码, 1.2秒后重新展码

  let authData = false;
  // const successed = await send34Data(data34);
  // if (successed) {
  // console.log('处理33数据' + new Date().getTime());
  // console.log('Mac码匹配成功,已返回账户认证码authData = ', authData);
  // setTimeout(() => {
  //   operatorDelegate.refreshQRCode(QR.getFastQR(), false);
  // }, 1200);

  const stTime = new Date().getTime();
  //console.log(`current time: ${stTime}`);
  authData = QR.qrCodeValidate(data);
  //console.log(`current time: ${new Date().getTime()} and cost: ${new Date().getTime() - stTime}`);
  // console.log('authData processTTData  处理33数据:>> ', authData);
  if (!authData) {
    console.error('Mac匹配失败，断开连接, 33报文: ' + data + ', 二维码: ', QR.getFastQR());

    //setTimeout(function(){
    //  alert('Mac匹配失败');
    //}, 1000);

    //authData = true
  } else {
    send34Data(data34, serverId);
    //setTimeout(function () {
    //  send34Data(data34);
    //}, 100)
  }

  operatorDelegate.refreshQRCode(QR.getFastQR(), true);
  refreshTime = setTimeout(() => {
    console.log('延时1.2秒后, 处理processTTData后延时1.2秒再展示二维码');
    operatorDelegate.refreshQRCode(QR.getFastQR(), false);
  }, 1500);

  // } else {
  //   console.log('蓝牙发送失败，账户认证码返回失败');
  //   // [SHMPointUtil saveGatesErrorWithType:SHMPointStatus_20009];
  // }
  // console.log('处理33数据,账户认证码 = ', data);
  // if (msgType !== '33') {
  // operatorDelegate.refreshQRCode(null, true);
  // }
  // 收到33 隐藏二维码
  // [self refreshQRCode:NO];
  isupDate = false;
  return authData;
}

async function rewriteAppCode(data, serverId) {
  // console.log('31数据长度updateData = ', updateData.length);
  if (updateData.length < 120) {
    // console.log('分包数据未接受完整');
    return;
  } else if (updateData.length == 120) {
    // console.log('31分包数据接受完整');
    // 如果是IOS rewriteQrCode方法内部进行了数据回写
    const updateDataCopy = updateData;
    const { uploadData, isNotMaglev, success } = QR.rewriteQrCode(updateDataCopy);

    // if (!isNotMaglev) {
    //   console.log('磁浮进出站，直接重启蓝牙');
    //   reStart();
    //   return
    // }

    if (uploadData && success) {
      // console.log("收到更新区命令返回应答数据");

      // 发送应答32数据
      // todo 发送 32 命令 记录时间
      const isSuccess = await sendUploadData(uploadData, serverId);
      const timestamp = dayjs().format('h:m:s:SSS');
      const log = {
        type: '32',
        time: timestamp,
        data: uploadData,
      };
      setLog(log);
      if (isSuccess) {
        // 二维码进行回写
        QR.rewriteAndroid(data);

        // 处理进出站成功数据
        processSuccessData(updateDataCopy, uploadData, isNotMaglev);
      } else {
        console.log('蓝牙回写失败');
        console.log('蓝牙回写失败');
      }
    } else {
      console.log('31数据校验失败');
    }
  } else {
    console.log('rewriteAppCode update长度异常');
    updateData = '';
    isupDate = false;
  }
}

/**
 处理进出站成功数据

 @param updateData data
 */
function processSuccessData(data, uploadData, isNotMaglev) {
  console.log('进出站成功');
  console.log('进出站成功');
  console.log('========================processSuccessData');
  console.log(data);
  console.log('========================processSuccessData');
  try {
    // const qrData = QR.getFastQR();
    // 二维码刷新
    // operatorDelegate.refreshQRCode(qrData, false);

    stationAction(data, uploadData, isNotMaglev);
  } catch (e) {
    console.log('startPeripheral e = ', e);
  }
}

function stationAction(data, uploadData, isNotMaglev) {
  console.log('BLEManager stationAction', data);
  if (uploadData.substr(0, 8) === '000C3201') {
    console.log('数据校验失败');
    return false;
  }
  const actionType = data.substr(6, 2);

  const isMaglev = !isNotMaglev; //
  console.log('是否是磁浮', isMaglev);

  console.log('=============actionType');
  console.log(actionType);
  console.log('=============actionType');
  let isInstation = false;
  let isBom = false;
  let stationId = '';
  if (actionType === '6a') {
    isInstation = true;
    stationId = data.substr(8, 8);
  } else if (actionType === '6b') {
    isInstation = false;
    stationId = data.substr(8, 8);
  }
  if (data.substr(6, 2) === '6c') {
    console.log('当前为Bom 进出站');
    isBom = true;
    const tmp = data.substr(32, 1) - 0;
    console.log('是bom进出站', tmp);
    if (isMaglev) {
      console.log('是磁浮进出站');
      stationId = data.substr(96, 8);
      const isIn = data.substr(94, 2);
      console.log('磁浮是否进出站值', isIn);
      isInstation = (parseInt(isIn, 16) + 0x100).toString(2).substring(1, 3) == '01' ? true : false; // true进站，false出站
      console.log('磁浮是否进出站值', isInstation);
    } else {
      console.log('不是磁浮进出站');
      if (tmp < 8) {
        console.log('bom进站');
        stationId = data.substr(34, 8);
        isInstation = true;
      } else {
        console.log('bom出站');
        stationId = data.substr(50, 8);
        isInstation = false;
      }
    }
  }
  console.log('===============');
  console.log('isInstation:' + isInstation);
  console.log('isBom:' + isBom);
  console.log('stationId:' + stationId);
  console.log('===============');
  // 进站刷新二维码，出站不在刷新
  // if (isInstation) {
  const qrData = QR.getFastQR();
  QR.setDataToLocal();
  //   console.log('qrData = ', qrData);
  //   // 二维码刷新
  //   operatorDelegate.refreshQRCode(qrData, false);
  // }
  clearTimeout(refreshTime);
  operatorDelegate.stationAction(isInstation, isBom, stationId, qrData, data);
}

export function send34Data(data34, serverId) {
  console.log(`发送34报文 ${data34} to ${serverId} at ${new Date().getTime()}`);
  if (isIOS) {
    upsdk.writeCharacteristicValue({
      serverId: serverId, // 替换BLEServerId， 改用本次写入数据的serverId, // createBLEPeripheralServer接口创建的server的serverId
      serviceId: UUID_SERVICE,
      characteristicId: UUID_READ, // characteristic对应的uuid
      value: data34, // 特征值对应的base64编码字符串
      needNotify: '1', // 是否需要通知主机value已更新，0:不通知；1:通知；
      success: function() {
        const endTime = new Date().valueOf();
        console.log('send34Data success endTime  = ' + endTime + ' data = ' + data34);
        // console.log('sendUploadData success diff  = ' + (endTime - timestamp) + ' data = ' + data);
      },
      fail: function(err) {
        console.error('[写数据失败][send34Data]', err);
      },
    });
  } else {
    upsdk.writeCharacteristicValue({
      serverId: serverId, // 替换BLEServerId， 改用本次写入数据的serverId, // createBLEPeripheralServer接口创建的server的serverId
      serviceId: UUID_SERVICE,
      characteristicId: UUID_READ, // characteristic对应的uuid
      value: data34, // 特征值对应的base64编码字符串
      needNotify: '1', // 是否需要通知主机value已更新，0:不通知；1:通知；
      callbackId: cbId, // 处理回包时使用
      success: function() {
        const endTime = new Date().valueOf();
        console.log('写数据完成回调时间' + new Date().getTime());
        console.log('send34Data success endTime  = ' + endTime + ' data = ' + data34);
        //console.log('send34Data success diff  = ' + (endTime - timestamp) + ' data = ' + data);
      },
      fail: function(err) {
        console.error('[写数据失败][send34Data]', err);
      },
    });

    console.log(`send34Data end ${new Date().getTime()}`);
  }
}

export function sendUploadData(data, serverId) {
  const timestamp = new Date().valueOf();
  console.log(
    '蓝牙发给闸机数据 sendUploadData to ' +
      serverId +
      ' startTime = ' +
      timestamp +
      ' data = ' +
      data,
  );
  /*
  console.log('入参 :>> ', data, {
    serverId: serverId, // 替换BLEServerId, 改用本次写入数据的serverId // createBLEPeripheralServer接口创建的server的serverId
    serviceId: UUID_SERVICE,
    characteristicId: UUID_READ, // characteristic对应的uuid
    value: hex2base64(data), // 特征值对应的base64编码字符串
    needNotify: '1', // 是否需要通知主机value已更新，0:不通知；1:通知；
    callbackId: cbId, // 处理回包时使用});
  });
  */
  return new Promise(function(resolve, reject) {
    if (isIOS) {
      upsdk.writeCharacteristicValue({
        serverId: serverId, // 替换BLEServerId, 改用本次写入数据的serverId, // createBLEPeripheralServer接口创建的server的serverId
        serviceId: UUID_SERVICE,
        characteristicId: UUID_READ, // characteristic对应的uuid
        value: hex2base64(data), // 特征值对应的base64编码字符串
        needNotify: '1', // 是否需要通知主机value已更新，0:不通知；1:通知；
        success: function() {
          resolve(true);
          const endTime = new Date().valueOf();
          console.log('sendUploadData success endTime  = ' + endTime + ' data = ' + data);
          // console.log('sendUploadData success diff  = ' + (endTime - timestamp) + ' data = ' + data);
        },
        fail: function(err) {
          console.log('[写数据失败][sendUploadData]', err);
          reject(err);
        },
      });
    } else {
      upsdk.writeCharacteristicValue({
        serverId: serverId, // 替换BLEServerId, 改用本次写入数据的serverId, // createBLEPeripheralServer接口创建的server的serverId
        serviceId: UUID_SERVICE,
        characteristicId: UUID_READ, // characteristic对应的uuid
        value: hex2base64(data), // 特征值对应的base64编码字符串
        needNotify: '1', // 是否需要通知主机value已更新，0:不通知；1:通知；
        callbackId: cbId, // 处理回包时使用
        success: function() {
          resolve(true);
          const endTime = new Date().valueOf();
          console.log('写数据完成回调时间' + new Date().getTime());
          console.log('sendUploadData success endTime  = ' + endTime + ' data = ' + data);
          console.log(
            'sendUploadData success diff  = ' + (endTime - timestamp) + ' data = ' + data,
          );
        },
        fail: function(err) {
          console.log('[写数据失败][sendUploadData]', err);
          reject(err);
        },
      });
    }
  });
}

function delayAction() {
  const timeOut = setTimeout(function() {
    console.log('延迟调用============');

    console.log('delayAction');

    if (!isSubscribe) {
      console.log('非连接状态，不需要重启');
      return;
    }
    if (isBomConnect) {
      console.log('bom 连接中 忽略断开机制');
      return;
    }
    clearTimeout(timeOut);
  }, 3000);
}

// 取消监听并刷新二维码
function disSubscribe() {
  try {
    const qrData = QR.getFastQR();
    console.log('取消监听并刷新二维码， 展示二维码');
    operatorDelegate.refreshQRCode(qrData, false);
    //console.log('连接断开不刷新二维码')
  } catch (e) {
    // loggerInfo(`SDK-getFastQR-${JSON.stringify(res)}`);
    console.log('startPeripheral e = ', e);
  }
}

// 重启
export async function reStart(flag) {
  console.log('删除BLE', BLEServerId);
  try {
    if (!BLEServerId) {
      console.log('reStart-没有BLEServerId，不做取消处理');
    } else {
      await stop();
      console.log('完成stop in reStart');
    }

    // await removeBroadCast();
    // await removeService();
    console.log('准备开始执行 start');
    return await start(flag);
  } catch (e) {
    console.log('reStart e = ', e);
  }
}

export async function stop() {
  if (!BLEServerId) {
    isAddEvent = false;
    console.log('stop-没有BLEServerId，不做取消处理');
    return;
  }
  if (!isAddEvent) {
    isAddEvent = false;
    console.log('没有正在开启的广播服务，不需要停止蓝牙服务，继续停止蓝牙服务');
    //return
  }
  console.log('stop 删除BLE');
  // removeBroadCast();
  // removeService();
  await removePeripheral()
  isAddEvent = false;
  console.log('完成removePeripheral');
}
export function getRemovePeripheral(serverId) {
  console.log('serverId',serverId)
  removePeripheral(serverId)
}
async function removePeripheral(serverId = BLEServerId) {
  console.log('closeBLEPeripheral serverId: ' + serverId);
  const stopReadNtification = new Promise((resolve, reject) => {
    console.log('开始调用sdk 取消监听已连接设备请求读取当前外围设备的特征值事件');
    upsdk.cancelCharacteristicReadNotification({
      serverId, // createBLEPeripheralServer接口创建的server的serverId
      success: function(res) {
        console.log(
          '取消监听已连接设备请求[读]取当前外围设备的特征值事件-success cancelCharacteristicReadNotification  ',
          res,
        );
        resolve();
      },
      fail: function(err) {
        // err= {code:'xxx'}
        console.log(
          '取消监听已连接设备请求读取当前外围设备的特征值事件-fail cancelCharacteristicReadNotification  ',
          err,
        );
        reject(err);
      },
    });
  }).catch(err=>{
    console.log('err',err)
  });

  const stopWriteNotification = new Promise((resolve, reject) => {
    console.log('开始调用sdk  取消监听已连接设备请求写当前外围设备的特征值事件');
    upsdk.cancelCharacteristicWriteNotification({
      serverId, // createBLEPeripheralServer接口创建的server的serverId
      success: function(res) {
        console.log(
          '取消监听已连接设备请求[写]当前外围设备的特征值事件-success cancelCharacteristicWriteNotification  ',
          res,
        );
        resolve();
      },
      fail: function(err) {
        // err= {code:'xxx'}
        console.log(
          '取消监听已连接设备请求[写]当前外围设备的特征值事件-fail cancelCharacteristicWriteNotification  ',
          err,
        );
        reject(err);
      },
    });
  }).catch(err=>{
    console.log('err',err)
  });

  const stopSubscribedNotification = new Promise((resolve, reject) => {
    console.log('开始调用sdk 取消监听特征值订阅事件');
    upsdk.cancelCharacteristicSubscribedNotification({
      serverId, // createBLEPeripheralServer接口创建的server的serverId
      success: function(res) {
        console.log(
          '取消监听特征值订阅事件-success cancelCharacteristicSubscribedNotification  ',
          res,
        );
        resolve();
      },
      fail: function(err) {
        // err= {code:'xxx'}
        console.log(
          '取消监听特征值订阅事件-fail cancelCharacteristicSubscribedNotification  ',
          err,
        );
        reject(err);
      },
    });
  }).catch(err=>{
    console.log('err',err)
  });

  const stopUnsubscribedNotification = new Promise((resolve, reject) => {
    console.log('开始调用sdk  取消监听取消特征值订阅事件');
    upsdk.cancelCharacteristicUnsubscribedNotification({
      serverId, // createBLEPeripheralServer接口创建的server的serverId
      success: function(res) {
        console.log(
          '取消监听取消特征值订阅事件-success cancelCharacteristicUnsubscribedNotification  ',
          res,
        );
        resolve();
      },
      fail: function(err) {
        // err= {code:'xxx'}
        console.log(
          '取消监听取消特征值订阅事件-fail cancelCharacteristicUnsubscribedNotification  ',
          err,
        );
        reject(err);
      },
    });
  }).catch(err=>{
    console.log('err',err)
  });
  const stopAdvert = new Promise((resolve, reject) => {
    upsdk.stopAdvertising({
      serverId,
      success(data) {
        isAddEvent = false;
        serverBroadcasting = false;
        console.log('停止广播。标记 isAddEvent = false', data);

        resolve(data);
      },
      fail(err) {
        console.log('停止广播。-fail', err);

        isAddEvent = false;
        serverBroadcasting = false;
        console.log('停止广播失败。重新标记 isAddEvent, serverBroadcasting = false');

        reject(err);
      },
    });
  }).catch(err=>{
    console.log('err',err)
  });

  var serviceId = UUID_SERVICE;
  console.log(
    '停止蓝牙服务时，调用removeService, serverId: ' + serverId + ', serviceId: ' + serviceId,
  );
  const stopService = new Promise((resolve, reject) => {
    upsdk.removeService({
      serverId: serverId,
      serviceId: serviceId,
      success: function(data) {
        console.log('removeService-success', data);
        resolve(data);
      },
      fail: function(err) {
        console.log('removeService-fail', err);
        reject(err);
      },
    });
  }).catch(err=>{
    console.log('err',err)
  });
  const stopBLEServer = new Promise((resolve, reject) => {
    upsdk.closeBLEPeripheralServer({
      serverId,
      success: function(data) {
        console.log('移除蓝牙外围服务-success', data);
        localStorage.setItem('newServerId', '');
        resolve(data);
      },
      fail: function(err) {
        console.log('移除蓝牙外围服务-fail', err);
        reject(err);
      },
    });
  }).catch(err=>{
    console.log('err',err)
  });

  let promiseArr = [
    stopBLEServer,
    stopWriteNotification,
    stopReadNtification,
    //stopService,
    stopAdvert,
  ];
  if (isIOS) {
    console.log('苹果进入这里')
    promiseArr.push(stopUnsubscribedNotification);
    promiseArr.push(stopSubscribedNotification);
  }

  console.log('promiseArr', promiseArr);

  await new Promise((resolve, reject) => {
    Promise.all(promiseArr)
      .then(result => {
        console.log('关闭服务成功', result);
        resolve(true);
      })
      .catch(err => {
        console.log('关闭服务失败', err);
        resolve(false);
      });
  }).catch(err=>{
    console.log('err',err)
  });
}

function checkData(data) {
  console.log('data', data);
  if (data.length !== 16 && data.length !== 14 && data.length !== 40) {
    console.log('数据长度不合法，直接返回False');
    return false;
  }

  if (data.length === 16 || data.length === 14) {
    if (data.substr(4, 2) !== '33') {
      console.log('33长度指令不对，直接返回 = ', data);
      return false;
    }
    if (data.length === 16 && data.substr(14, 2) === '01') {
      console.log('物理bom连接');
      isBomConnect = true;
    }

    //        SHMLog(@"正常33数据");
  }
  if (data.length === 20 && !isupDate) {
    if (data.substr(4, 2) !== '31') {
      console.log('31长度指令不对，直接返回 = %@', data);
      return false;
    }
  }
  return true;
}
export function setLog(log) {
  EventBus.$emit('setLog', log);
}

function station(isInstation, isBom, stationId) {
  const action = {
    isInstation,
    isBom,
    stationId,
  };
  EventBus.$emit('station', action);
}

// 删除所有老的serverId 并重启蓝牙
async function removeAllOldPeripheralAndStart() {
  console.log('removeAllOldPeripheralAndStart: ', serverIds);
  const [serverIds] = await getLocalData({
    key: 'allServerIds',
  });
  if (serverIds && serverIds.length) {
    serverIds.forEach(async id => {
      await removePeripheral(id);
    });
    // await delLocalData('allServerIds');
    await setLocalData({
      key: 'allServerIds',
      value: [],
    });
    await start();
  }
}

// 缓存所有生成的serverId
async function addServerId2Local(serverId) {
  const [serverIds] = await getLocalData({
    key: 'allServerIds',
  });
  await setLocalData({
    key: 'allServerIds',
    value: [...(serverIds || []), ...[serverId]],
    // value: [serverId],
  });
}
